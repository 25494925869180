export default {
    log_in: {
        es: 'Iniciar Sesión',
        us: 'Log In',
        fr: 'Se connecter',
        pt: 'Iniciar sessão',
        it: 'Accedi'
    },
    user: {
        es: 'Usuario',
        us: 'User',
        fr: 'Utilisateur',
        pt: 'Usuário',
        it: 'Utente'
    },
    password: {
        es: 'Contraseña',
        us: 'Password',
        fr: 'Mot de passe',
        pt: 'Senha',
        it: 'Password'
    },
    enter: {
        es: 'Entrar',
        us: 'Enter',
        fr: 'Entrer',
        pt: 'Entrar',
        it: 'Entra'
    },
    latest_10_vehicles: {
        es: 'Últimos 10 vehículos',
        us: 'Latest 10 vehicles',
        fr: 'Derniers 10 véhicules',
        pt: 'Últimos 10 veículos',
        it: 'Ultimi 10 veicoli'
    },
    bulletins: {
        es: 'Boletines',
        us: 'Bulletins',
        fr: 'Bulletins',
        pt: 'Boletins',
        it: 'Bollettini'
    },
    learning: {
        es: 'Formación',
        us: 'Learning',
        fr: 'Formation',
        pt: 'Formação',
        it: 'Formazione'
    },
    processes: {
        es: 'Procesos',
        us: 'Processes',
        fr: 'Processus',
        pt: 'Processos',
        it: 'Processi'
    },
    our_brands: {
        es: 'Nuestras marcas',
        us: 'Our brands',
        fr: 'Nos marques',
        pt: 'Nossas marcas',
        it: 'I nostri marchi'
    },
    search_code_by_dtc: {
        es: 'Buscar código por DTC',
        us: 'Search code by DTC',
        fr: 'Rechercher le code par DTC',
        pt: 'Buscar código por DTC',
        it: 'Cerca codice per DTC'
    },
    model: {
        es: 'Modelo',
        us: 'Model',
        fr: 'Modèle',
        pt: 'Modelo',
        it: 'Modello'
    },
    from: {
        es: 'Desde',
        us: 'From',
        fr: 'De',
        pt: 'Desde',
        it: 'Da'
    },
    to: {
        es: 'Hasta',
        us: 'To',
        fr: 'À',
        pt: 'Até',
        it: 'A'
    },
    type: {
        es: 'Tipo',
        us: 'Type',
        fr: 'Type',
        pt: 'Tipo',
        it: 'Tipo'
    },
    engine: {
        es: 'Motor',
        us: 'Engine',
        fr: 'Moteur',
        pt: 'Motor',
        it: 'Motore'
    },
    power: {
        es: 'Potencia',
        us: 'Power',
        fr: 'Puissance',
        pt: 'Potência',
        it: 'Potenza'
    },
    displacement: {
        es: 'Cilindrada',
        us: 'Displacement',
        fr: 'Cylindrée',
        pt: 'Cilindrada',
        it: 'Cilindrata'
    },
    sensors: {
        es: 'Sensores',
        us: 'Sensors',
        fr: 'Capteurs',
        pt: 'Sensores',
        it: 'Sensori'
    },
    actuators: {
        es: 'Actuadores',
        us: 'Actuators',
        fr: 'Actionneurs',
        pt: 'Atuadores',
        it: 'Attuatori'
    },
    sensors_potentiometers_pickups: {
        es: 'Captadores, sensores, potenciómetros...',
        us: 'Sensors, potentiometers, pickups...',
        fr: 'Capteurs, potentiomètres, pickups...',
        pt: 'Captadores, potenciómetros, sensores...',
        it: 'Sensori, potenziometri, pickup...'
    },
    solenoid_valves_servomotors_regulators_injectors: {
        es: 'Electroválvulas, servomotores, reguladores, inyectores...',
        us: 'Solenoid valves, servomotors, regulators, injectors...',
        fr: 'Électrovannes, servomoteurs, régulateurs, injecteurs...',
        pt: 'Válvulas solenoides, servomotores, reguladores, injetores...',
        it: 'Valvole solenoidi, servomotori, regolatori, iniettori...'
    },
    view_processes: {
        es: 'Ver procesos',
        us: 'View processes',
        fr: 'Voir les processus',
        pt: 'Ver processos',
        it: 'Visualizza processi'
    },
    view_syllabus: {
        es: 'Ver temarios',
        us: 'View syllabus',
        fr: 'Voir le programme',
        pt: 'Ver programa',
        it: 'Visualizza programma'
    },
    code_search: {
        es: 'Búsqueda del código',
        us: 'Code search',
        fr: 'Recherche de code',
        pt: 'Busca de código',
        it: 'Ricerca codice'
    },
    description: {
        es: 'Descripción',
        us: 'Description',
        fr: 'Description',
        pt: 'Descrição',
        it: 'Descrizione'
    },
    reason: {
        es: 'Razón',
        us: 'Reason',
        fr: 'Raison',
        pt: 'Razão',
        it: 'Motivo'
    },
    issue: {
        es: 'Problema',
        us: 'Issue',
        fr: 'Problème',
        pt: 'Problema',
        it: 'Problema'
    },
    cause: {
        es: 'Causa',
        us: 'Cause',
        fr: 'Cause',
        pt: 'Causa',
        it: 'Causa'
    },
    solution: {
        es: 'Solución',
        us: 'Solution',
        fr: 'Solution',
        pt: 'Solução',
        it: 'Soluzione'
    },
    send_suggestion: {
        es: 'Enviar Sugerencia',
        us: 'Send suggestion',
        fr: 'Envoyer une suggestion',
        pt: 'Enviar sugestão',
        it: 'Invia suggerimento'
    },
    symptom: {
        es: 'Síntoma',
        us: 'Symptom',
        fr: 'Symptôme',
        pt: 'Sintoma',
        it: 'Sintomo'
    },
    disclaimer_clause: {
        es: 'Cláusula de exención de responsabilidad',
        us: 'Disclaimer clause',
        fr: 'Clause de non-responsabilité',
        pt: 'Cláusula de isenção de responsabilidade',
        it: 'Clausola di esclusione della responsabilità'
    },
    login_error: {
        es: 'Usuario o contraseña incorrectos',
        us: 'Incorrect username or password',
        fr: 'Nom d\'utilisateur ou mot de passe incorrect',
        pt: 'Nome de usuário ou senha incorretos',
        it: 'Nome utente o password errati'
    },
    select_bulletin: {
        es: 'Seleccione un boletín',
        us: 'Select a bulletin',
        fr: 'Sélectionnez une bulletin',
        pt: 'Selecione um boletim',
        it: 'Seleziona un bollettino'
    },
    suggestion: {
        es: 'Sugerencia',
        us: 'Suggestion',
        fr: 'Suggestion',
        pt: 'Sugestão',
        it: 'Suggerimento'
    },
    system_courses: {
        es: 'Cursos por sistema',
        us: 'System courses',
        fr: 'Cours par système',
        pt: 'Cursos por sistema',
        it: 'Corsi per sistema'
    },
    vehicle_courses: {
        es: 'Cursos por vehículo',
        us: 'Vehicle courses',
        fr: 'Cours par véhicule',
        pt: 'Cursos por veículo',
        it: 'Corsi per veicolo'
    },
    management_courses: {
        es: 'Cursos de gestión',
        us: 'Management courses',
        fr: 'Cours de gestion',
        pt: 'Cursos de gestão',
        it: 'Corsi di gestione'
    },
    thank_you: {
        es: '¡Gracias!',
        us: 'Thank you!',
        fr: 'Merci!',
        pt: 'Obrigado!',
        it: 'Grazie!'
    },
    manufacturer: {
        es: 'Fabricante',
        us: 'Manufacturer',
        fr: 'Fabricant',
        pt: 'Fabricante',
        it: 'Produttore'
    },
    warning: {
        es: 'Advertencia',
        us: 'Warning',
        fr: 'Avertissement',
        pt: 'Aviso',
        it: 'Avvertimento'
    },
    ia_warning: {
        es: 'La inteligencia artificial proporcionará respuestas que pueden resultar útiles, sin embargo estas <b>no están supervisadas</b> y pueden contener errores o información incorrecta. Por ello es importante tenerlo en consideración a la hora de emprender acciones a partir de la respuesta. Ante cualquier duda, te recomendamos consultar a nuestros técnicos de <b>NextAssist</b>.',
        us: 'Artificial intelligence will provide answers that may be useful, however, these are <b>not supervised</b> and may contain errors or incorrect information. Therefore, it is important to take this into consideration when taking actions based on the response. If in doubt, we recommend consulting our <b>NextAssist</b> technicians.',
        fr: 'L’intelligence artificielle fournira des réponses qui peuvent s’avérer utiles, cependant, celles-ci <b>ne sont pas supervisées</b> et peuvent contenir des erreurs ou des informations incorrectes. Il est donc important de prendre cela en considération lors de la prise de décisions basées sur la réponse. En cas de doute, nous vous recommandons de consulter nos techniciens <b>NextAssist</b>.',
        pt: 'A inteligência artificial fornecerá respostas que podem ser úteis, no entanto, estas <b>não são supervisionadas</b> e podem conter erros ou informações incorretas. Portanto, é importante levar isso em consideração ao tomar ações com base na resposta. Em caso de dúvida, recomendamos que consulte nossos técnicos da <b>NextAssist</b>.',
        it: 'L’intelligenza artificiale fornirà risposte che possono essere utili, tuttavia queste <b>non sono supervisionate</b> e possono contenere errori o informazioni errate. Pertanto, è importante tenerne conto quando si prendono decisioni basate sulla risposta. In caso di dubbio, consigliamo di consultare i nostri tecnici <b>NextAssist</b>.'
    },
    ia_accept: {
        es: 'Entendido',
        us: 'Understood',
        fr: 'D’accord',
        pt: 'Entendido',
        it: 'Capito'
    },
    ia_warning_short: {
        es: 'La IA puede cometer errores. Considera verificar la información importante.',
        us: 'AI may make mistakes. Consider verifying important information.',
        fr: 'L’IA peut commettre des erreurs. Pensez à vérifier les informations importantes.',
        pt: 'A IA pode cometer erros. Considere verificar informações importantes.',
        it: 'L’IA può commettere errori. Considera di verificare le informazioni importanti.'
    },
    inquiry: {
        es: 'Consulta',
        us: 'Inquiry',
        fr: 'Consultation',
        pt: 'Consulta',
        it: 'Consultazione'
    },
    make_inquiry: {
        es: 'Realizar Consulta',
        us: 'Make an Inquiry',
        fr: 'Réaliser une Consultation',
        pt: 'Realizar Consulta',
        it: 'Effettuare una consultazione'
    },
    response: {
        es: 'Respuesta',
        us: 'Response',
        fr: 'Réponse',
        pt: 'Resposta',
        it: 'Risposta'
    },
}
