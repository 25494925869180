<template>
  <v-container>

    <v-dialog
        v-model="warning"
        width="500"
    >
      <v-card>
        <v-card-title class="text-h5 bg-dark red--text">
          <v-icon left color="red">mdi-alert</v-icon>{{ TEXT('warning') }}
        </v-card-title>

        <v-card-text class="mt-4" v-html="TEXT('ia_warning')"></v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="pb-6">
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="warning = false"
          >
            {{TEXT('ia_accept')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card dark rounded="xl">
      <v-card-title>
        <v-icon left x-large color="white" class="pb-2">mdi-robot</v-icon>
        IA
      </v-card-title>
      <v-card-text class="overflow-y-auto pt-3" style="height: calc(100dvh - 370px);">
        <v-textarea
            name="response"
            :label="TEXT('response')"
            v-model="response"
            outlined
            no-resize
            readonly
            auto-grow
            :loading="waiting"
            :disabled="!response.length"
        ></v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-text-field
            rounded
            name="ask"
            ref="askTextField"
            :label="TEXT('inquiry')"
            v-model="query"
            :hint="TEXT('ia_warning_short')"
            outlined
            autofocus
            @keyup.enter.prevent="ask"
            :append-icon="query.length ? 'mdi-arrow-up' : ''"
            @click:append="ask"
        >
        </v-text-field>

      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import api from "@/api";

export default {
  name: "IAChatView",
  data() {
    return {
      query: '',
      response: '',
      waiting: false,
      warning: true,
    }
  },
  computed: {
    ...mapGetters(['TEXT']),
    ...mapState([
      'language'
    ]),
  },
  methods: {
    ask() {
      this.$refs.askTextField.blur();
      this.waiting = true
      api.ASK_TO_GPT(this.query, this.language)
          .then(response => {
            if (response.data.auth) {
              this.response = response.data.completion.choices[0].message.content;
              this.waiting = false
            } else {
              this.$router.push('/')
            }
          })

    }
  }
}
</script>

<style scoped>

</style>